
import { defineComponent, ref, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  components: {
    TmButton,
    TmStatus,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const isCampaignLinked = ref(false)
    const currentStatus = ref(0)

    const statusList = [
      null,
      {
        name: 'draft',
        color: 'gray',
        outline: true,
      },
      {
        name: 'action required',
        color: 'orange',
        outline: false,
      },
      {
        name: 'in review',
        color: 'blue',
        outline: true,
      },
      {
        name: 'canceled',
        color: 'red',
        outline: true,
      },
      {
        name: 'approved',
        color: 'green',
        outline: true,
      },
    ]

    const activeStatus = computed(() => statusList[currentStatus.value])

    return {
      activeStatus,
      currentStatus,
      isCampaignLinked,
    }
  },
})
