
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmButton from '@/components/shared/TmButton.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import { useByoc } from '@/compositions/services/useByoc'
import { useModals } from '@/compositions/modals'
import ChatsNumberDetailsTollFree from '@/components/pages/chats/settings/numberDetails/ChatsNumberDetailsTollFree.vue'
import ChatsNumberDetails10dlc from '@/components/pages/chats/settings/numberDetails/ChatsNumberDetails10dlc.vue'

export default defineComponent({
  components: {
    ChatsNumberDetails10dlc,
    ChatsNumberDetailsTollFree,
    InfoCardRow,
    TmButton,
    TmFlag,
    WhiteBlock,
    DetailsHero,
    InfoCard,
    ChatsSettingsPage,
  },
  setup() {
    const { openModal } = useModals()
    const { openEditLabelModal, openCancelNumberModal } = useByoc()
    const handleAssignee = () => {
      openModal('singleAssignee', {
        label: 'Automatically assign to',
        btnText: 'Save',
        modalTitle: 'Assignee',
      })
    }

    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'SMS, MMS and voice calls', url: { name: 'base.chats.settings.channels' } },
      { label: '(365) 555-7663' },
    ]

    const infos = [
      { label: 'Marketing USA' },
    ]
    const previewPlaying = ref(false)

    return {
      handleAssignee,
      openEditLabelModal,
      openModal,
      openCancelNumberModal,
      breadcrumbs,
      infos,
      previewPlaying,
    }
  },
})
