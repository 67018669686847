
import { defineComponent, ref, computed } from 'vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { StatusType, ButtonColor } from '@/definitions/shared/types'

type VerificationStatusType = {
  name: string;
  text: any;
  btnText: string;
  showWarning: boolean;
  status?: StatusType;
  btnColor?: ButtonColor;
}

export default defineComponent({
  components: {
    TmStylizedText,
    TmButton,
    TmStatus,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const currentStatus = ref(0)
    const formNotVerifiedText = [
      'This number cannot be used for sending messages, because the toll-free verification form ',
      {
        text: 'has not yet been fully verified.',
        style: 'semi-bold',
      },
    ]

    const statusList: VerificationStatusType[] = [
      {
        name: 'completeVerification',
        text: formNotVerifiedText,
        btnColor: 'primary',
        btnText: 'Complete verification',
        showWarning: true,
      },
      {
        name: 'draft',
        text: formNotVerifiedText,
        btnColor: 'primary',
        btnText: 'Edit form',
        status: {
          name: 'draft',
          color: 'gray',
          outline: true,
        },
        showWarning: true,
      },
      {
        name: 'actionRequired',
        text: formNotVerifiedText,
        btnColor: 'primary',
        btnText: 'Fix verification form',
        status: {
          name: 'action required',
          color: 'orange',
          outline: false,
        },
        showWarning: true,
      },
      {
        name: 'inReview',
        text: formNotVerifiedText,
        btnText: 'View verification form',
        status: {
          name: 'in review',
          color: 'blue',
          outline: true,
        },
        showWarning: true,
      },
      {
        name: 'canceled',
        text: [
          'This number cannot be used for sending messages, because the toll-free verification form ',
          {
            text: 'has not yet been cancelled by the carrier.',
            style: 'semi-bold',
          },
        ],
        btnColor: 'primary',
        btnText: 'Start new verification',
        status: {
          name: 'canceled',
          color: 'red',
          outline: true,
        },
        showWarning: true,
      },
      {
        name: 'verified',
        text: [
          'Your number is ',
          {
            text: 'verified.',
            style: 'semi-bold',
          },
          ' You can send unlimited messages from this number.',
        ],
        btnText: 'View verification form',
        status: {
          name: 'verified',
          color: 'green',
          outline: true,
        },
        showWarning: false,
      },
    ]

    const activeStatus = computed<VerificationStatusType>(() => statusList[currentStatus.value])

    return {
      activeStatus,
      currentStatus,
    }
  },
})
